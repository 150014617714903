.decarb-table-no-rows {
  height: 300px;
  display: flex;
  align-items: center;
}

.ag-horizontal-left-spacer,
.ag-horizontal-right-spacer {
  overflow-x: auto;
}

.ag-center-cols-viewport {
  min-height: 72px !important;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a8a8a8; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}
.motif-table.ag-theme-quartz .ag-header-cell, .motif-table.ag-theme-quartz .ag-cell{
  user-select: text;
}